import { Input, Spin, Table, Tabs, TreeSelect, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CONTROL_ID } from "../../AppWrapper";
import { JURE_FORMAT_DATE } from "../../helpers/consts";
import nestedSorting from "../../helpers/NestedSorting";
import { GET_MACHINES } from "../../redux/modules/Machines/actions";
import { GET_ORDERS } from "../../redux/modules/Maintenances/actions";

const Planing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orders } = useSelector((state) => state.maintenancesReducer);
  const [filtersVisible, set_filtersVisible] = useState(false);
  const [selectedAsset, set_selectedAsset] = useState([]);
  const [search, set_search] = useState("");
  const [possibleFilters, set_possibleFilters] = useState({
    tags: [],
    assets: [],
  });

  const { machines } = useSelector((state) => state.machinesReducer);

  useEffect(() => {
    dispatch({ type: GET_MACHINES });
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Opis",
      dataIndex: "name",
      key: "name",
      render: (text, value) => (
        <a
          onClick={() => {
            history.push(`/maintenances/${value.id}/about`);
          }}
        >
          {value.name}
        </a>
      ),
    },
    {
      title: "Imovina",
      dataIndex: "items",
      key: "items",
      render: (text, value) => (
        <a
          onClick={() => {
            history.push(`/machines/${value.items[0]?.id}/specifications`);
          }}
        >
          {value.items[0]?.name}
        </a>
      ),
    },
    {
      title: "Planirani pocetak",
      dataIndex: "planned_start",
      key: "planned_start",
      render: (text, value) => (
        <span style={{ fontWeight: "bold" }}>{moment(value.planned_start).format(JURE_FORMAT_DATE)}</span>
      ),
    },
    {
      title: "Podimovina",
      dataIndex: "tags",
      key: "tags",
      render: (text, value) => value.tags?.map((x) => x.name).join(" ") || "-",
    },
    {
      title: "Kategorija",
      dataIndex: "category",
      key: "category",
      render: (text, value) => value.categories.map((x) => x.name).join(", "),
    },
  ];

  useEffect(() => {
    let assets = [];
    orders.data.forEach((x) => {
      if (assets.filter((y) => y.key == x.items[0]?.id).length === 0) {
        assets.push({
          key: x.items[0]?.id.toString(),
          title: x.items[0]?.name,
          children: [],
        });
      }
    });
    let tags = [];
    orders.data.forEach((x) => {
      x.tags?.forEach((y) => {
        if (tags.filter((k) => k.id == y.id).length === 0);
        tags.push({
          key: y.id.toString(),
          title: y.name,
        });
      });
    });

    set_possibleFilters({
      tags,
      assets,
    });
  }, [orders]);

  useEffect(() => {
    dispatch({ type: GET_ORDERS, query_params: { maintenance_type: 1 + "|" + CONTROL_ID, from: "planning" } });
  }, []);

  let filtredOrders = [...orders.data];

  if (selectedAsset.length > 0) {
    filtredOrders = filtredOrders.filter((x) => {
      return selectedAsset.includes(x.items[0]?.id);
    });
  }

  if (search) {
    filtredOrders = filtredOrders?.filter((x) =>
      x?.tags
        ?.map((y) => y?.name)
        .join("")
        ?.toLowerCase()
        .includes(search)
    );
  }

  let preventiveMaintenances = filtredOrders.filter((x) => x?.type?.id === 1);
  let controlMaintenances = filtredOrders.filter((x) => x?.type?.id === CONTROL_ID);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography.Title level={4}>Planiranje održavanja</Typography.Title>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Input
                placeholder="Unesi podimovinu"
                onChange={({ target: { value } }) => {
                  debounce(() => {
                    set_search(value);
                  }, 600);
                }}
              />
            </div>
            <div style={{ width: 33 }} />
            <div>
              <TreeSelect
                placeholder="Imovina"
                style={{ width: 300, marginLeft: 15 }}
                showSearch
                multiple={true}
                allowClear={true}
                onChange={(a) => {
                  set_selectedAsset(a);
                }}
                treeData={nestedSorting(machines.data, "parent")}
                optionFilterProp="title"
                filterTreeNode={(input, option) => {
                  return option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey="preventivna">
        <Tabs.TabPane tab="Preventivna" key="preventivna">
          <Spin spinning={orders.status === "loading"}>
            <Table
              pagination={{ pageSize: 50 }}
              rowKey={(record) => record.id}
              dataSource={preventiveMaintenances}
              columns={columns}
            />
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Kontrole" key="control">
          <Spin spinning={orders.status === "loading"}>
            <Table
              pagination={{ pageSize: 50 }}
              rowKey={(record) => record.id}
              dataSource={controlMaintenances}
              columns={columns}
            />
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default Planing;

function debounce(fn, delay) {
  let timer;
  return (() => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  })();
}
