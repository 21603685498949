import React, { useState, useEffect } from "react";
import moment from "moment";
import useWindowDimensions from "../../helpers/useWindowDimension";
import { connect, useDispatch } from "react-redux";

import * as actions from "../../redux/modules/Maintenances/actions";

import Spinner from "../../components/LoadingScreen";
import MaintenanceTable from "../../components/Table/maintenace";

import {
  PageHeader,
  Input,
  Alert,
  DatePicker,
  Button,
  Modal,
  Icon,
  Spin,
} from "antd";

import { AudioOutlined } from "@ant-design/icons";

import Scheduler from "./components/Scheduler";
import Admin_Categories from "./components/Categories";



const Maintenances = ({
  accountingPrepareRegistration,
  history,
}) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [functionsAddVisible, setFunctionsAddVisible] = useState(false);
  const { width, height } = useWindowDimensions();

 
  const functionsAdd = (bool) => {
    setFunctionsAddVisible(bool);
  };

  
  const toggleModal = () => {
    setIsModalVisible((previousState) => !previousState);
  };


 

  return (
    <>
      <PageHeader
        style={{ paddingTop: "0 !important" }}
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // margin: "10px 0",
            }}
          >
            <h1 style={{ fontSize: 20, fontWeight: 600 }}>
              Povijest održavanja
            </h1>

            <div style={{ display: "flex" }}>
              <Button
                style={{ marginRight: 12 }}
                onClick={() => functionsAdd(true)}
                type="default"
              >
                Kategorija održavanja <Icon type="plus" />
              </Button>
              <Button onClick={toggleModal}>Kalendar</Button>
            </div>
          </div>
        }
      />


      <MaintenanceTable />



      <hr />

      <Modal
        className={"antdModal"}
        style={{ height: "81vh", overflow: "auto", maxWidth: "900px" }}
        visible={functionsAddVisible}
        onCancel={() => functionsAdd(false)}
        footer={null}
        children={
          <Admin_Categories
            categories={accountingPrepareRegistration.data.user_categories}
          />
        }
      />

      {/* Scheduler */}
      <Modal
        visible={isModalVisible}
        destroyOnClose={true}
        footer={null}
        width={width * 0.8}
        centered
        style={{ overflow: "hidden" }}
        closable={false}
        onCancel={toggleModal}
      >
        <Scheduler />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    accountingPrepareRegistration: state.accoutingReducer.prepareRegistration,
  };
};

export default connect(mapStateToProps, null)(Maintenances);
