import React, { Component } from "react";
import { connect } from "react-redux";

import Spinner from "../../../../components/LoadingScreen";
import * as actions from "../../../../redux/modules/Accounting/actions";
import { setUserModel } from "../../../../helpers/accounting";

import SizeType from "../../../../helpers/deviceTypeSize";

import {
  Row,
  Col,
  Upload,
  Form,
  Input,
  Alert,
  Button,
  Select,
  Icon,
  Radio,
  Checkbox,
  TreeSelect,
  Typography,
} from "antd";
import nestedSorting from "../../../../helpers/NestedSorting";
import Popover from "../Popover";
import childsOfParent from "../../../../helpers/cildsOfParent";
import { TYPES_OF_USERS } from "../../../../helpers/consts";

const { Option } = Select;
const { TextArea } = Input;
const { SHOW_PARENT } = TreeSelect;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class TechnicanForm extends Component {
  state = {
    successVisible: false,
    fileList: [],
    can_report: false,
    should_confirm: true,
    can_update_parts: false,
    should_get_maintenance_email_report: false,
    insurance_mail: false,
    should_receive_notifications: false,
  };

  componentDidMount() {
    if (this.props.update) {
      this.setState({
        can_report: this.props.user.can_report,
        should_confirm: this.props.user.should_confirm,
        can_update_parts: this.props.user.can_update_parts,
        insurance_mail: this.props.user.insurance_mail,
        should_get_maintenance_email_report:
          this.props.user.should_get_maintenance_email_report,
        should_receive_notifications:
          this.props.user.should_receive_notifications,
      });
    }
  }

  alertSuccess = () => {
    this.setState({ successVisible: true });
    this.props.form.resetFields();
    setTimeout(() => {
      this.setState({ successVisible: false });
    }, 2000);
  };

  beforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  removeFile = (file) => {
    let fileList = this.state.fileList.concat();
    const index = fileList.indexOf(file);
    fileList.splice(index, 1);
    this.setState({ fileList, fileList });
  };

  isEmailExsits = (vlue) => {
    if (this.props.update) {
      return false;
    }

    const value = this.props.form.getFieldValue("email");
    let retrunValue = false;

    const check = this.props.prepareRegistration.data.emails.find(
      (item) => item === value
    );
    if (check !== -1 && check !== undefined) {
      this.props.form.setFields({
        email: {
          errors: [new Error("Email vec postoji!")],
        },
      });
      retrunValue = true;
    }

    return retrunValue;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err && !this.isEmailExsits()) {
        values.can_update_parts =
          values.type === "WRH" ? true : this.state.can_update_parts;
        values.should_get_maintenance_email_report =
          this.state.should_get_maintenance_email_report;
        values.should_receive_notifications =
          this.state.should_receive_notifications;
        values.insurance_mail = this.state.insurance_mail;
        values.can_report =
          values.type !== "MAN" &&
          values.type !== "LDR" &&
          values.type !== "REP"
            ? this.state.can_report
            : true;
        values.should_confirm =
          values.type !== "MAN" &&
          values.type !== "LDR" &&
          values.type !== "REP"
            ? this.state.should_confirm
            : false;
        values.category = values.type;
        values.first_name = this.props.importFromERP
          ? this.props.erp_users.data.find(
              (item) => item.erp_id === +values.erp_id
            ).name
          : values.first_name;

        values.last_name = this.props.importFromERP ? "" : values.last_name;

        if (!values.categories) {
          values.categories = [];
        }

        let formData = new FormData();
        formData.append("data", JSON.stringify(values));

        this.state.fileList.forEach((item) => {
          formData.append("files", item);
        });

        if (this.props.update) {
          this.props.onUpdateUser(
            values.type,
            formData,
            this.props.user.id,
            this.alertSuccess.bind(this)
          );
        } else {
          this.props.onRegisterUser(
            values.type,
            formData,
            this.alertSuccess.bind(this)
          );
        }
      }
    });
  };

  clearItem = (el) => {
    console.log(el);
  };

  addEventLisitner = (cssClass) => {
    //clear listeners
    // recreateNode("ant-select-selection__rendered", true);

    //add listeners
    const elms = document.getElementsByClassName(cssClass);

    for (let index = 0; index < elms.length; index++) {
      const element = elms[index];
      element.addEventListener("click", () => {
        this.clearItem(element);
      });
    }
  };

  render() {
    if (
      this.props.prepareRegistration.status === "loading" ||
      this.props.assets.status === "loading"
    ) {
      return <Spinner />;
    }

    const { getFieldDecorator, getFieldsError, getFieldValue } =
      this.props.form;

    const {
      erp_users,
      prepareRegistration,
      assets_categories,
      registerUser,
      importFromERP,
      update,
      user,
      updateUser,
      assets,
      locations,
    } = this.props;

    const model = setUserModel(user, update);

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    const alertSuccess = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1", maxWidth: "400px" }}
        message="Uspješno!"
        type="success"
      />
    );
    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol:
        SizeType() === "xs" || SizeType() === "sm"
          ? {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 0,
              },
            }
          : {
              span: 20,
              offset: 0,
            },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    const alertMinOneFeature = (
      <Alert
        style={{
          margin: "0 auto",
          marginBottom: "10px",
          zIndex: "1",
          maxWidth: "400px",
        }}
        message="Korisnik mora imati minimalno jednu funkciju!"
        type="warning"
      />
    );

    const plainOptions = ["Apple", "Pear", "Orange"];

    return (
      <div style={{ padding: "0 20px" }}>
        <Row>
          <Col>
            {" "}
            <h2
              style={{
                textAlign: "center",
                margin: "0 auto",
                marginBottom: "20px",
                maxWidth: "100%",
              }}
            ></h2>
            <div>
              {registerUser.status === "error" || updateUser.status === "error"
                ? alert
                : null}
              {this.state.successVisible ? alertSuccess : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {" "}
            <Form
              className={"userForm"}
              {...formItemLayout}
              //   style={{ maxWidth: "600px" }}
              layout="horizontal"
              onSubmit={this.handleSubmit}
            >
              <Form.Item label="Tip korisnika">
                {getFieldDecorator("type", {
                  initialValue: model.type,
                  rules: [{ required: true, message: "Ovo polje je obavezno" }],
                })(
                  <Radio.Group buttonStyle="solid">
                    {TYPES_OF_USERS.map((item, index) => (
                      <Radio.Button key={index} value={item.value}>
                        {item.text}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
              {importFromERP ? (
                <>
                  <Form.Item label="Naziv">
                    {getFieldDecorator("erp_id", {
                      initialValue: update ? user.user.erp_id : null,
                      rules: [{ required: true, message: "Odaberi funkciju" }],
                    })(
                      <Select
                        mode="single"
                        allowClear={true}
                        size={"large"}
                        showSearch
                        placeholder="Odaberi naziv"
                        optionFilterProp="name"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {erp_users.data.map((item) => (
                          <Option key={item.erp_id} value={item.erp_id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item style={{ display: "none" }} label="Prezime">
                    {getFieldDecorator("last_name", {
                      initialValue: model.last_name,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(<Input placeholder="Prezime" />)}
                  </Form.Item>
                </>
              ) : (
                <>
                  {" "}
                  <Form.Item label="Ime">
                    {getFieldDecorator("first_name", {
                      initialValue: model.first_name,
                      rules: [
                        {
                          required: true,
                          message: "Unesite ime!",
                        },
                      ],
                    })(<Input placeholder="Ime" />)}
                  </Form.Item>
                  <Form.Item label="Prezime">
                    {getFieldDecorator("last_name", {
                      initialValue: model.last_name,
                      rules: [
                        {
                          required: true,
                          message: "Unesite prezime",
                        },
                      ],
                    })(<Input placeholder="Prezime" />)}
                  </Form.Item>
                </>
              )}
              <Form.Item label="PIS ID">
                {getFieldDecorator("codeks_id", {
                  initialValue: model.codeks_id,
                  rules: [{ required: false, message: "Unesite e-mail!" }],
                })(<Input placeholder="PIS ID" />)}
              </Form.Item>
              <Form.Item label="E-mail">
                {getFieldDecorator("email", {
                  initialValue: model.email,
                  rules: [
                    { required: true, message: "Unesite e-mail!" },
                    {
                      type: "email",
                      message: "Nepravilan unos E-mail!",
                    },
                  ],
                })(
                  <Input onSelect={this.isEmailExsits} placeholder="E-mail" />
                )}
              </Form.Item>

              <Form.Item label="Imovina:">
                <Popover
                  left={-104}
                  title="Imovina"
                  text="Ako izabereš imovinu koja ima podimovinu automatski će biti dodana i ta podimovina"
                />
                {getFieldDecorator("assets", {
                  initialValue: model.assets,
                  rules: [{ required: true, message: "Odaberi imovinu" }],
                })(
                  <TreeSelect
                    showSearch
                    multiple={true}
                    allowClear={true}
                    placeholder="Odaberi imovinu"
                    treeData={nestedSorting(
                      getAllssets(assets.data, user.assets),
                      "parent",
                      childsOfParent(
                        this.props.form.getFieldValue("assets"),
                        getAllssets(assets.data, user.assets)
                      )
                    )}
                    optionFilterProp="title"
                    filterTreeNode={(input, option) => {
                      console.log(input, option);
                      return (
                        option.props.name
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                )}
              </Form.Item>

              {this.props.form.getFieldValue("type") !== "WRH" && (
                <Form.Item label="Kategorija">
                  {getFieldDecorator("categories", {
                    initialValue: model.categories,
                    rules: [
                      { required: true, message: "Ovo polje je obavezno" },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      allowClear={true}
                      placeholder="Odaberi kategoriju"
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {prepareRegistration.data.user_categories.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              <Form.Item label={"Lokacija"}>
                {getFieldDecorator("location", {
                  initialValue: model.location,
                  rules: [
                    {
                      required: true,
                      message: "Ovo polje je obavezno",
                    },
                  ],
                })(
                  <TreeSelect
                    allowClear={true}
                    placeholder="Odaberi lokaciju"
                    treeData={nestedSorting(locations, "location_child")}
                  ></TreeSelect>
                )}
              </Form.Item>

              <Form.Item label="Adresa">
                {getFieldDecorator("address", {
                  initialValue: model.address,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<Input placeholder="Adresa" />)}
              </Form.Item>

              <Form.Item label="Broj telefona">
                {getFieldDecorator("phone_number", {
                  initialValue: model.phoneNumber,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<Input type="tel" placeholder="Broj telefona" />)}
              </Form.Item>

              <Form.Item style={{ display: "none" }} label="Kategorija imovine">
                {getFieldDecorator("facility_subcategories", {
                  initialValue: model.facility_subcategories,
                })(
                  <TreeSelect
                    showSearch
                    multiple={true}
                    allowClear={true}
                    placeholder="Odaberi imovinu"
                    treeData={nestedSorting(
                      assets_categories.map((x) => x),
                      "parent",
                      this.props.form.getFieldValue("facility_subcategories")
                    )}
                    optionFilterProp="title"
                    filterTreeNode={(input, option) => {
                      console.log(input, option);
                      return (
                        option.props.name
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                )}
              </Form.Item>

              <Form.Item label="Napomena">
                {getFieldDecorator("remark", {
                  initialValue: model.remark,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<TextArea rows={3} placeholder="Napomena" />)}
              </Form.Item>
              <Form.Item label="Broj uređaja">
                {getFieldDecorator("device_id", {
                  initialValue: model.deviceNumber,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<Input placeholder="Broj uređaja" />)}
              </Form.Item>

              {this.props.form.getFieldValue("type") !== "REP" &&
                this.props.form.getFieldValue("type") !== "LDR" &&
                this.props.form.getFieldValue("type") !== "MAN" && (
                  <Form.Item label="Prijava">
                    <Popover
                      left={-84}
                      title="Prijava"
                      text="Omogući korisniku prijavu održavanja"
                    />
                    <Checkbox
                      checked={this.state.can_report}
                      onChange={() => {
                        this.setState({ can_report: !this.state.can_report });
                      }}
                    />
                  </Form.Item>
                )}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  paddingLeft: 40,
                }}
              >
                {(this.props.form.getFieldValue("type") === "LDR" ||
                  this.props.form.getFieldValue("type") === "MAN" ||
                  this.props.form.getFieldValue("type") === "REP") && (
                  <Form.Item style={{ width: "50%", display: "flex" }}>
                    <Typography.Text style={{ padding: "0 10px" }}>
                      Prima obavijest
                    </Typography.Text>
                    <Popover
                      left={-20}
                      title="Primati obavijest"
                      text="Omogući korisniku primanje obavijesti"
                    />
                    <Checkbox
                      checked={this.state.should_receive_notifications}
                      onChange={() => {
                        this.setState({
                          should_receive_notifications:
                            !this.state.should_receive_notifications,
                        });
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item style={{ width: "50%", display: "flex" }}>
                  <Popover
                    left={-20}
                    title="Primati izvještaj"
                    text="Omogući korisniku primanje izvještaja o održavanju"
                  />
                  <Typography.Text style={{ padding: "0 10px" }}>
                    Izvještaj
                  </Typography.Text>
                  <Checkbox
                    checked={this.state.should_get_maintenance_email_report}
                    onChange={() => {
                      this.setState({
                        should_get_maintenance_email_report:
                          !this.state.should_get_maintenance_email_report,
                      });
                    }}
                  />
                </Form.Item>

                {this.props.form.getFieldValue("type") !== "REP" &&
                this.props.form.getFieldValue("type") !== "LDR" &&
                  this.props.form.getFieldValue("type") !== "MAN" && (
                    <Form.Item style={{ width: "50%", display: "flex" }}>
                      <Popover
                        left={-20}
                        title="Potvrda"
                        text="Omogući naknadnu potvrdu izvršenja održavanja"
                      />
                      <Typography.Text style={{ padding: "0 10px" }}>
                        Potvrda
                      </Typography.Text>
                      <Checkbox
                        checked={this.state.should_confirm}
                        onChange={() => {
                          this.setState({
                            should_confirm: !this.state.should_confirm,
                          });
                        }}
                      />
                    </Form.Item>
                  )}

                {this.props.form.getFieldValue("type") !== "WRH" && (
                  <Form.Item style={{ width: "50%", display: "flex" }}>
                    <Popover
                      left={-20}
                      title="Dijelovi"
                      text="Omogući korisniku automatsko skidanje dijelova sa skladišta"
                    />
                    <Typography.Text style={{ padding: "0 10px" }}>
                      Dijelovi
                    </Typography.Text>
                    <Checkbox
                      checked={this.state.can_update_parts}
                      onChange={() => {
                        this.setState({
                          can_update_parts: !this.state.can_update_parts,
                        });
                      }}
                    />
                  </Form.Item>
                )}

                {this.props.form.getFieldValue("type") === "MAN" && (
                  <Form.Item style={{ width: "50%", display: "flex" }}>
                    <Popover
                      left={-20}
                      title="Osiguranje"
                      text="Omogući korisniku primanje maila ukoliko je nastala šteta za osiguranje!"
                    />
                    <Typography.Text style={{ padding: "0 10px" }}>
                      Osiguranje
                    </Typography.Text>
                    <Checkbox
                      checked={this.state.insurance_mail}
                      onChange={() => {
                        this.setState({
                          insurance_mail: !this.state.insurance_mail,
                        });
                      }}
                    />
                  </Form.Item>
                )}
              </div>

              <Form.Item {...tailFormItemLayout} style={{ marginTop: 40 }}>
                <Button
                  loading={
                    registerUser.status === "loading" ||
                    updateUser.status === "loading"
                  }
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Spremi
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registerUser: state.accoutingReducer.registerUser,
    updateUser: state.accoutingReducer.updateUser,
    prepareRegistration: state.accoutingReducer.prepareRegistration,
    assets_categories:
      state.machinesReducer.prepareRegistration.data.categories,
    locations: state.machinesReducer.prepareRegistration.data.locations,
    assets: state.machinesReducer.machines,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRegisterUser: (topic, data, callback) =>
      dispatch({ type: actions.REGISTER_USER, topic, data, callback }),
    onUpdateUser: (topic, data, user_id, callback) =>
      dispatch({ type: actions.UPDATE_USER, topic, data, user_id, callback }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(TechnicanForm));

export const getAllssets = (assets, user_assets) => {
  let arr = assets;

  console.log(assets, user_assets, "assets");

  user_assets.forEach((element) => {
    if (!user_assets.map((x) => x.id).includes(element.id)) {
      arr.push(element);
    }
  });

  return arr;
};

function recreateNode(parentElement, withChildren) {
  const elements = document.getElementsByClassName(parentElement);

  for (let index = 0; index < elements.length; index++) {
    const el = elements[index];
    if (withChildren) {
      el.parentNode.replaceChild(el.cloneNode(true), el);
    } else {
      var newEl = el.cloneNode(false);
      while (el.hasChildNodes()) newEl.appendChild(el.firstChild);
      el.parentNode.replaceChild(newEl, el);
    }
  }
}
